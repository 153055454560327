import React from "react";
import TaskList from "./components/TaskList";

export const App = () => (
    <>
      <h1>Welcome to the TODO List</h1>
      <TaskList />
    </>
  );

